<template>
  <nav class="nav-main navbar navbar-expand-sm navbar-dark bg-dark fixed-top">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">
        <img src="../../assets/images/logo-white.svg" width="40" height="40" alt="">
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarContent">
        <ul class="navbar-nav ms-auto">
          
          <li class="nav-item mt-2 mt-sm-0 me-sm-3 home" :class="{'active': state=='home'}">
            <router-link class="nav-link" :to="{ name: 'Home' }">
              <span>Home</span>
            </router-link>
          </li>
          <li class="nav-item mt-2 mt-sm-0 me-sm-3 profile">
            <a class="nav-link" @click="editProfile()"><span>Profile</span></a>
          </li>
          <li v-if="superadmin" class="nav-item mt-2 mt-sm-0 me-sm-3 groups" :class="{'active': state=='users'}">
            <router-link class="nav-link" :to="{ name: 'Users' }">
              <span>Groups</span>
            </router-link>
          </li>
          
          <li class="nav-item mt-2 mt-sm-0 me-sm-3 notice">
            <a class="nav-link" @click="openNotice()"><span>Notice</span></a>
          </li>

          <signout-component />
          
        </ul>
        
      </div>
    </div>
  </nav>

  <profile-component @updated="editedProfile" @canceled="editProfileCanceled" :editAction="editAction" />
  <notice-component @canceled="openNoticeCanceled" :openNoticeAction="openNoticeAction" />
</template>

<script>
import SignoutComponent from '@/components/navigation/SignoutComponent.vue';
import NoticeComponent from '@/components/navigation/NoticeComponent.vue';

import EditProfileComponent from '../profile/EditProfileComponent.vue'

export default {
  name: 'HomeNavbarComponent',
  components: {
    "signout-component": SignoutComponent,
    "notice-component": NoticeComponent,
    "profile-component": EditProfileComponent
  },  
  props: {
    superadmin: Boolean,
    state: String
  },
  data: function () {
    return {
      editAction: false,
      openNoticeAction: false
    }
  },
  methods: {
    editProfile()
    {
      this.editAction = true;
    }, 
    editedProfile(user)
    {

      this.editAction = false;
      if(user.disconnected || user.banned)
      {
        this.$router.push( { name: 'Signin', params: {error: "Please reconnect"} }); 
      }
      
    },
    editProfileCanceled()
    {
      this.editAction = false;
    },
    openNotice()
    {
      this.openNoticeAction = true;
    },
    openNoticeCanceled()
    {
      this.openNoticeAction = false;
    }
  }
}
</script>