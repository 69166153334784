<template>
<div id="noticeModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header no-print">
          <h5 class="modal-title">{{ $t("notice.title") }}</h5>
          <button type="button" class="btn-close " @click="cancel()" aria-label="Close"></button>
        </div>
        <div class="modal-body">

          <div class="to-print">

            <h5 class="modal-title no-screen">{{ $t("notice.title") }}</h5>

            <div class="mb-3">
              <button type="button" class="btn btn-primary no-print" @click="print()">{{ $t("notice.printLabel") }}</button>
            </div>  


            <div class="mb-3 text-center">
              <img src="@/assets/images/controllers.png" alt="controllers schema" class="w-50">
            </div>  

            <p class="border-bottom">{{ $t("notice.instructions") }}</p>

            <div class="row session" v-for="session in sessions" v-bind:key="session.id">
              <div class="col name pt-2">{{ session.name }}</div>
              <div class="col name">
                <combination-component v-if="session.combination" :initialCombination="formatCombination(session.combination)" :disabled="true" />
              </div>
            </div>

            <p class="mt-5 border-bottom">{{ $t("notice.instructions_reserved") }}</p>
            <div v-if="parameters">
              <div class="row" v-for="(reservedCombination, index) in parameters.reservedCombinations" v-bind:key="index">
                <div class="col name pt-2">{{ $t("notice.reservedCombination."+reservedCombination.label) }}</div>
                <div class="col name">
                  <combination-component :initialCombination="convertStringToCombination(reservedCombination.value)" :disabled="true" />
                </div>
              </div>
            </div>


            <p class="mt-5 border-bottom">{{ $t("notice.instructions_vibrations") }}</p>

            <div class="row">
              <div class="col name pt-2">{{ $t("notice.waiting_vibration_label") }}</div>
              <div class="col name pt-2 vibrations d-flex">
                <div class="vibration vibration-1"><img src="@/assets/images/vibration1.svg" /></div><div class="vibration vibration-1"><img src="@/assets/images/vibration1.svg" /></div><div class="silence"><img src="@/assets/images/silence1.svg" /></div><div class="silence"><img src="@/assets/images/silence1.svg" /></div><div class="silence"><img src="@/assets/images/silence1.svg" /></div><div class="silence"><img src="@/assets/images/silence1.svg" /></div>
              </div>
            </div>

            <div class="row">
              <div class="col name pt-2">{{ $t("notice.confirmation_vibration_label") }}</div>
              <div class="col name pt-2 vibrations d-flex">
                <div class="vibration vibration-3"><img src="@/assets/images/vibration3.svg" /></div><div class="silence"><img src="@/assets/images/silence1.svg" /></div><div class="silence"><img src="@/assets/images/silence1.svg" /></div><div class="silence"><img src="@/assets/images/silence1.svg" /></div><div class="silence"><img src="@/assets/images/silence1.svg" /></div>
              </div>
            </div>

            <div class="row">
              <div class="col name pt-2">{{ $t("notice.playing_vibration_label") }}</div>
              <div class="col name pt-2 vibrations d-flex">
                <div class="vibration vibration-1"><img src="@/assets/images/vibration1.svg" /></div><div class="silence"><img src="@/assets/images/silence1.svg" /></div><div class="silence"><img src="@/assets/images/silence1.svg" /></div><div class="silence"><img src="@/assets/images/silence1.svg" /></div><div class="silence"><img src="@/assets/images/silence1.svg" /></div>
              </div>
            </div>

            <div class="row">
              <div class="col name pt-2">{{ $t("notice.searching_vibration_label") }}</div>
              <div class="col name pt-2 vibrations d-flex">
                <div class="vibration vibration-1"><img src="@/assets/images/vibration1.svg" /></div><div class="vibration vibration-1"><img src="@/assets/images/vibration1.svg" /></div><div class="vibration vibration-1"><img src="@/assets/images/vibration1.svg" /></div><div class="silence"><img src="@/assets/images/silence1.svg" /></div><div class="silence"><img src="@/assets/images/silence1.svg" /></div><div class="silence"><img src="@/assets/images/silence1.svg" /></div><div class="silence"><img src="@/assets/images/silence1.svg" /></div>
              </div>
            </div>

            <div class="row">
              <div class="col name pt-2">{{ $t("notice.updating_vibration_label") }}</div>
              <div class="col name pt-2 vibrations d-flex">
                <div class="vibration vibration-1"><img src="@/assets/images/vibration1.svg" /></div><div class="vibration vibration-1"><img src="@/assets/images/vibration1.svg" /></div><div class="vibration vibration-1"><img src="@/assets/images/vibration1.svg" /></div><div class="vibration vibration-1"><img src="@/assets/images/vibration1.svg" /></div><div class="silence"><img src="@/assets/images/silence1.svg" /></div><div class="silence"><img src="@/assets/images/silence1.svg" /></div><div class="silence"><img src="@/assets/images/silence1.svg" /></div><div class="silence"><img src="@/assets/images/silence1.svg" /></div>
              </div>
            </div>




          </div>
          
        </div>
        <div class="modal-footer no-print">
          <button type="button" class="btn btn-secondary" @click="cancel()">{{ $t("notice.closeLabel") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from 'bootstrap';
import $ from 'jquery'


import eventConfig from '@/configs/eventConfig';
import sessionService from '@/services/sessionService';
import parametersService from '@/services/parametersService';

import CombinationComponent from '@/components/session/CombinationComponent.vue';

export default {
  name: 'NoticeComponent',
  props: {
    openNoticeAction: Boolean
  },
  components: {
    'combination-component': CombinationComponent,
  },
  watch: 
  { 
    openNoticeAction: function() {
      if(this.openNoticeAction)
      {
        
        Promise.all([this.loadParameters(), this.loadSessions()]).then(() => {
          this.openModal();
        });
        
      }
    }
  },
  data: function () {
    return {
      sessions: null,
      parameters: null,
      updateCombination: [
        {
          letter: "L1",
          selected: true
        },
        {
          letter: "L2",
          selected: true
        },
        {
          letter: "R1",
          selected: true
        },
        {
          letter: "R2",
          selected: true
        }
      ],
      outroCombination: [
        {
          letter: "L1",
          selected: true
        },
        {
          letter: "L2",
          selected: true
        }
      ]
    }
  },
  mounted () {
    
    this.noticeModal = new Modal($('#noticeModal'));
    
    this.eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
      this.cancel();
    });

  },
  methods: {
    convertStringToCombination(combinationString)
    {
      var letters = ["a", "b", "x", "y", "l1", "l2", "r1", "r2"];
      var combination = [];
      for(var letter of letters)
      {
        if(combinationString.indexOf(letter.toUpperCase()) > -1)
        {
          combination.push(
            {
              letter: letter.toUpperCase(),
              selected: true
            }
          )
        }
      }

      return combination;
    },
    formatCombination(combination)
    {
      let formattedCombination = [];
      for(let letter of combination)
      {
        formattedCombination.push(
          {
            letter: letter,
            selected: true
          }
        );
      }

      return formattedCombination;
    },
    loadParameters() {  
      
      parametersService.get().then(
        response => {
          var parameters = response;
          this.parameters = parameters;
        },
        errors => {
          
        }
      );
    },
    loadSessions() {  
      
      sessionService.getList().then(
        response => {
          var sessions = response;
          
          this.sessions = sessions;
        },
        errors => {
          
        }
      );
    },
    cancel()
    {
      this.noticeModal.hide();
      this.$emit(eventConfig.CANCELED);
    },
    openModal() {
      this.noticeModal.show();
    },
    print() {
      window.print();
    }
  }
}
</script>
<style scoped>
  .name {
    line-height: 40px;
  }
  

  .vibration {
    background-repeat: no-repeat;
    background-position: top left;
    height: 13px;
    margin-left: 2px;
    margin-right: 2px;
  }

  .vibration.vibration-1 {
    width: 13px;
  }

  .vibration.vibration-2 {
    width: 26px;
  }

  .vibration.vibration-3 {
    width: 39px;
  }  

  .vibration.vibration-4 {
    width: 52px;
  }    

  .silence {
    background-repeat: no-repeat;
    background-position: bottom left;
    width: 13px;
    height: 13px;
    margin-left: 2px;
    margin-right: 2px;
  }

  .no-screen {
    display: none;
  }

  @media print {
    .no-print {
      display: none;
    }

    .no-screen {
      display: block;
    }

    .to-print {
      background-color: white;
      
    }

    #noticeModal {
      height: auto !important;
      position: absolute !important;
      overflow-y: initial !important;
      background-color: white;
    }
    #noticeModal .modal-content {
      overflow: initial !important;
    }
    #noticeModal .modal-body {
      overflow-y: initial !important;
    }

    #noticeModal .modal-dialog-scrollable {
      height: auto !important;
    }

    body {
      overflow: initial !important;
    }


  }
</style>