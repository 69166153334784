<template>
  <div id="page" class="container-sm h-100 d-flex">
    <div class="justify-content-center align-self-center d-block w-100">
      <img src="../assets/images/logo.svg" class="mx-auto d-block mb-4" alt="">
      <p class="lead text-center">{{ $t("signin.introduction") }}</p>
      <form @submit.prevent="signin">
        
        <div class="form-group mb-3">
          <label for="emailInput">{{ $t("signin.emailLabel") }}</label>
          <input id="emailInput" type="email" class="form-control" :class="{ 'is-invalid': emailError }" v-model="email">
          <div v-if="emailError" class="invalid-feedback">
            {{emailError}}
            
          </div>          
        </div>
        <div class="form-group mb-3">
          <label for="passwordInput">{{ $t("signin.passwordLabel") }}</label>
          <input type="password" class="form-control" :class="{ 'is-invalid': passwordError }" id="passwordInput" v-model="password">
          <div v-if="passwordError" class="invalid-feedback">
            {{passwordError}}
          </div>
        </div>

        <div class="form-group mb-3 underline">
          <a @click="passwordLost()">{{ $t("signin.passwordLostLabel") }}</a>
        </div>
        
        <div v-if="$route.params.error" class="alert alert-danger" role="alert">
          {{ $route.params.error }}
        </div>

        <div v-if="error" class="alert alert-danger" role="alert">
          {{error}}
        </div>
        
        <button type="submit" class="btn btn-primary">{{ $t("signin.enterButtonLabel") }}</button>
      </form>
    
    </div>
  </div>

  <password-lost-component @canceled="passwordLostEnded" :action="passwordLostAction" />
</template>

<script>

import authService from '../services/authService';
import redirectService from '../services/redirectService';
import PasswordLostComponent from '@/components/signin/PasswordLostComponent.vue'

export default {
  name: 'SigninView',
  components: {
    'password-lost-component': PasswordLostComponent
  },  
  data: function () {
    return {
      email: null,
      emailError: null,
      password: null,
      passwordError: null,
      error: null,
      passwordLostAction: false
    }
  },
  mounted(){
  },
  methods: {

    passwordLost()
    {
      this.passwordLostAction = true;
    },
    passwordLostEnded()
    {
      this.passwordLostAction = false;
    },
    parseErrors(data)
    {
      if(data.errors)
      {
        for(let error of data.errors)
        {
          
          switch(error.param)
          {
            case 'email':
              this.emailError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'password':
              this.passwordError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            
          }
        }
      }else
      {

        this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
      }
    },
    signin() {
      this.emailError = null;
      this.passwordError = null;
      this.error = null;

      authService.signin(this.email, this.password).then(
        response => {
          if(redirectService.getRedirectRoute())
          {
            this.$router.push(redirectService.getRedirectRoute().path); 
            redirectService.removeRedirectRoute();
          }else
          {
            this.$router.push('/'); 
          }
          
        },
        errors => {
          if(errors.msg)
          {
            this.parseErrors({msg: errors.msg});
          }else
          {
            this.parseErrors(errors.response.data);
          }
          
        }
      );
      
    }


  }
}
</script>

