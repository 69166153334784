<template>

  <div id="downloadSessionModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("session.download.title") }}</h5>
          <button type="button" class="btn-close" @click="close()" aria-label="Close"></button>
        </div>
        <div v-if="session" class="modal-body">
          <p>{{ $t("session.download.instructions") }}</p>

            <div v-if="introVideoURL" class="form-group d-flex">
              <div class="form-label">
              <a class="action download" :href="introVideoURL">{{ $t("session.download.introVideoLabel") }}</a>
              </div>
            </div>

            <div v-if="loopVideoURL" class="form-group d-flex">
              <div class="form-label">
              <a class="action download" :href="loopVideoURL">{{ $t("session.download.loopVideoLabel") }}</a>
              </div>
            </div>

            <div v-if="outroVideoURL" class="form-group d-flex">
              <div class="form-label">
              <a class="action download" :href="outroVideoURL">{{ $t("session.download.outroVideoLabel") }}</a>
              </div>
            </div>
          
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="close()">{{ $t("session.update.closeLabel") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from 'bootstrap';
import $ from 'jquery'

import sessionService from '../../services/sessionService';
import eventConfig from '@/configs/eventConfig';

export default {
  name: 'SessionDownloadComponent',
  props: {
    sessionToDownload: Object
  },
  watch: 
  { 
    sessionToDownload: function() {
      if(this.sessionToDownload)
      {
        this.session = {};
        var sessionData = JSON.parse(JSON.stringify(this.sessionToDownload));
        this.session.id = sessionData.id;
        
        this.session.introVideo = sessionData.introVideo;
        this.session.loopVideo = sessionData.loopVideo;
        this.session.outroVideo = sessionData.outroVideo;

        this.loadPlayableSession();
      }
    }
  },
  data: function () {
    return {
      session: null,
      introVideoURL: null,
      loopVideoURL: null,
      outroVideoURL: null
    }
  },
  components: {
    
  },
  mounted () {
    
    this.downloadModal = new Modal($('#downloadSessionModal'));
    
    this.eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
      this.close();
    });

  },
  methods: {
    close()
    {
      this.downloadModal.hide();
      this.$emit(eventConfig.CLOSED);
    },
    loadPlayableSession() {
      sessionService.getPlayableSession(this.session.id).then(
        response => {
          this.introVideoURL = response.introVideo;
          this.loopVideoURL = response.loopVideo;
          this.outroVideoURL = response.outroVideo;

          this.openModal();
        },
        errors => {
          if(errors.response)
          {
            this.parseErrors(errors.response.data);
          }
        }
      );
    },
    openModal() {
      
      this.downloadModal.show();
    }
  }
}
</script>

<style scoped lang="scss">

a.action {
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 25px;
  height: 35px;
  line-height: 35px;
  padding-right: 30px;
  display: inline-block;
}

.actions a span {
  display: none;
}
a.action.download {
  background-image: url(../../assets/images/ico-download.svg);
}
</style>