<template>
  <router-view>
    
  </router-view>
  <div v-if="mode != 'prod'" class="ms-4 me-4 alert alert-warning alert-dismissible fade show fixed-bottom" role="alert">
  <strong>Attention</strong> vous travaillez sur la version <strong>{{ mode }}</strong> du projet.
  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>
</template>

<script>

export default {
  name: 'App',
  data: function () {
    return {
      mode: process.env.VUE_APP_MODE
    }
  },
  components: {
  }
}
</script>