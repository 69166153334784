import axios from 'axios';
import cookieService from './cookieService';
import eventDispatcher from '../eventDispatcher'

import eventConfig from '@/configs/eventConfig';
var axiosSource = axios.CancelToken.source();

export default class apiService {


    static get (url, useUserToken=true) {
        
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_API_URL+url, {
                headers: {
                'Authorization': 'Bearer ' +((useUserToken) ? cookieService.getToken() : process.env.VUE_APP_API_BEARER_TOKEN),
                "Content-Type": "application/json",
                }
            })
            .then(response => {
                resolve(response);
            })
            .catch(function (error) {
                console.log(error);
                reject(error);
                /*
                if(error.response.status == 401)
                {
                    eventDispatcher.emit(eventConfig.UNAUTHORIZED);
                }else
                {
                    reject(error);
                }
                */
            });
         });
       
    }

    static cancelUpload() {
        axiosSource.cancel('Operation canceled by the user.');
    }

    static uploadGCS(url, data) {
        
        axiosSource = axios.CancelToken.source();

        const onUploadProgress = (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = (loaded * 100) / total;
            eventDispatcher.emit(eventConfig.PROGRESS, percent);
        };
            
        return new Promise((resolve, reject) => {
            
            try{
                
                
                var h = {
                    "Content-Type": "application/octet-stream"
                }
                
                axios.put(url, data.file, {
                    cancelToken: axiosSource.token,
                    headers: h,
                    onUploadProgress
                })
                .then(response => {
                    resolve(response);
                })
                .catch(function (error) {

                    console.log(error);
                    reject(error);
                    /*
                    if(error.response.status == 401)
                    {
                        eventDispatcher.emit(eventConfig.UNAUTHORIZED);
                    }else
                    {
                        reject(error);
                    }
                    */
                });

            } catch (e) {
                console.log(e);
            }
        });

    }
    
    static upload (url, headers, data, useUserToken=true) {
        axiosSource = axios.CancelToken.source();
            
        return new Promise((resolve, reject) => {
            var formData = new FormData();
            
            for (const [key, value] of Object.entries(data)) {
                
                formData.append(key, value);
            }

            var h = {
                'Authorization': 'Bearer ' + ((useUserToken) ? cookieService.getToken() : process.env.VUE_APP_API_BEARER_TOKEN),
                "Content-Type": "multipart/form-data"
            }

            for (const [key, value] of Object.entries(headers)) {
                
                h[key] = value;
            }
            
            axios.post(process.env.VUE_APP_API_URL+url, formData, {
                cancelToken: axiosSource.token,
                headers: h
            })
            .then(response => {
                resolve(response);
            })
            .catch(function (error) {
                console.log(error);
                reject(error);
                /*
                if(error.response.status == 401)
                {
                    eventDispatcher.emit(eventConfig.UNAUTHORIZED);
                }else
                {
                    reject(error);
                }
                */
            });
        });
    
    }


    static post (url, data, useUserToken=true) {
        
        return new Promise((resolve, reject) => {
           
            axios.post(process.env.VUE_APP_API_URL+url, data, {
                headers: {
                'Authorization': 'Bearer ' + ((useUserToken) ? cookieService.getToken() : process.env.VUE_APP_API_BEARER_TOKEN),
                "Content-Type": "application/json",
                }
            })
            .then(response => {
                resolve(response);
            })
            .catch(function (error) {
                console.log(error);
                reject(error);
                /*
                if(error.response.status == 401)
                {
                    eventDispatcher.emit(eventConfig.UNAUTHORIZED);
                }else
                {
                    reject(error);
                }
                */
            });
         });
       
    }


    static delete (url, useUserToken=true) {
        
        return new Promise((resolve, reject) => {
            axios.delete(process.env.VUE_APP_API_URL+url, {
                headers: {
                'Authorization': 'Bearer ' + ((useUserToken) ? cookieService.getToken() : process.env.VUE_APP_API_BEARER_TOKEN),
                "Content-Type": "application/json",
                }
            })
            .then(response => {
                resolve(response);
            })
            .catch(function (error) {
                console.log(error);
                reject(error);
                /*
                if(error.response.status == 401)
                {
                    eventDispatcher.emit(eventConfig.UNAUTHORIZED);
                }else
                {
                    reject(error);
                }
                */
            });
         });
       
    }
    
    static patch (url, data, useUserToken=true) {
        
        return new Promise((resolve, reject) => {
            axios.patch(process.env.VUE_APP_API_URL+url, data, {
                headers: {
                'Authorization': 'Bearer ' + ((useUserToken) ? cookieService.getToken() : process.env.VUE_APP_API_BEARER_TOKEN),
                "Content-Type": "application/json",
                }
            })
            .then(response => {
                resolve(response);
            })
            .catch(function (error) {
                console.log(error);
                reject(error);
                /*
                if(error.response.status == 401)
                {
                    eventDispatcher.emit(eventConfig.UNAUTHORIZED);
                }else
                {
                    reject(error);
                }
                */
            });
         });
       
    }


}
