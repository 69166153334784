<template>
  <div class="container-fluid after-two-navbar">
    <div class="row session border-bottom" v-for="session in sessions" v-bind:key="session.id">
      <div class="col name">{{ session.name }}</div>
      <div class="col name">
        <combination-component v-if="session.combination" :initialCombination="formatCombination(session.combination)" :disabled="true" />
      </div>
      <!--<div class="col name infos pt-2">
        <div v-if="session.introVideo && session.loopVideo && session.outroVideo" class="video-complete" @click="$emit(eventConfig.VIEW, session)">
          <span>View</span>
        </div>
      </div>-->
      <div class="col actions d-flex justify-content-end">
        <a class="dl" @click="$emit(eventConfig.DOWNLOAD, session)">
          <span>Download</span>
        </a>
        <a v-if="session.managingSessionAllowed" class="edit" @click="$emit(eventConfig.UPDATE, session)">
          <span>Edit</span>
        </a>
        <a v-if="superadmin" class="delete" @click="$emit(eventConfig.DELETE, session)">
          <span>Delete</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>

import sessionService from '../../services/sessionService';
import privilegeConfig from '@/configs/privilegeConfig';
import eventConfig from '@/configs/eventConfig';

import CombinationComponent from '@/components/session/CombinationComponent.vue';

export default {
  name: 'SessionListComponent',
  props: {
    superadmin: Boolean
  },
  data: function () {
    return {
      sessions: null,
      eventConfig: eventConfig
    }
  },
  components: {
    'combination-component': CombinationComponent,
  },
  watch: 
  { 
    superadmin: function() {
      if(typeof this.superadmin != 'undefined')
      {
        this.loadSessions();
      }
    }
  },
  mounted () {

    this.eventDispatcher.on(eventConfig.RELOAD_SESSION, () => {
      this.loadSessions();
    });

  },
  methods: {
    formatCombination(combination)
    {
      let formattedCombination = [];
      for(let letter of combination)
      {
        formattedCombination.push(
          {
            letter: letter,
            selected: true
          }
        );
      }

      return formattedCombination;
    },
    loadSessions() {  
      sessionService.getList().then(
        response => {
          var sessions = response;
          
          for(var i=0; i<sessions.length; i++)
          {

            if(this.superadmin || sessions[i].privileges.includes(privilegeConfig.MANAGE_SESSION))
            {
              sessions[i].managingSessionAllowed = true;
            }else
            {
              sessions[i].managingSessionAllowed = false;
            }

            if(this.superadmin || sessions[i].privileges.includes(privilegeConfig.READ_GROUP))
            {
              sessions[i].readingGroupAllowed = true;
            }else
            {
              sessions[i].readingGroupAllowed = false;
            }
          }
          this.sessions = sessions;
        },
        errors => {
          
        }
      );
    }
  }
}
</script>

<style scoped lang="scss">

.session {
  padding: 10px;
  padding-left: 40px;
}

h6, .session .name {
  line-height: 40px;
}

.infos div {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  display: inline-block;
  width: 40px;
  height: 40px;
}

.actions a {
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  width: 40px;
  height: 40px;
}

.actions a span {
  display: none;
}

.infos div span {
  display: none;
}

.infos div.video-complete {
  background-image: url(../../assets/images/ico-video-complete.svg);
}

.actions a.edit {
  background-image: url(../../assets/images/ico-edit.svg);
}

.actions a.groups {
  background-image: url(../../assets/images/ico-group.svg);
}

.actions a.delete {
  background-image: url(../../assets/images/ico-trash.svg);
}



.actions a.dl {
  background-image: url(../../assets/images/ico-video-dl.svg);
}
</style>