class eventConfig {
  static PROGRESS = 'PROGRESS_EVENT';
  static UNAUTHORIZED = 'UNAUTHORIZED_EVENT';
  
  
  static NEW = 'new';
  static VIEW = 'view';
  static UPDATE = 'update';
  static DELETE = 'delete';
  static DOWNLOAD = 'download';
  
  static DISCONNECT = 'disconnect';
  static BAN = 'ban';
  static CHANGE = 'change';


  static CREATED = 'created';
  static CANCELED = 'canceled';
  static CLOSED = 'closed';
  static UPDATED = 'updated';
  static DELETED = 'deleted';
  
  static LOADED = 'loaded';
  static DISCONNECTED = 'disconnected';
  static BANNED = 'banned';

 }

module.exports = eventConfig;