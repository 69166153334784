<template>
  
  <nav class="navbar navbar-light bg-light border-bottom">
      <div class="container-fluid ps-2 pe-0">
        <span></span>
        <a class="nav-link mb-2" @click="createUser()">
            <img src="@/assets/images/ico-add.svg" alt="Add user">
        </a>
      </div>
  </nav>
  <div class="row m-0 flex-grow-1">
    <div class="col col-12 p-0">

      <table class="table">
        <thead>
          <tr>
            <th scope="col">{{ $t("user.list.firstName") }}</th>
            <th scope="col">{{ $t("user.list.lastName") }}</th>
            <th scope="col">{{ $t("user.list.email") }}</th>
            <th scope="col">{{ $t("user.list.superAdmin") }}</th>
            <th scope="col">{{ $t("user.list.confirmed") }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" v-bind:key="user.id">
            <td>{{user.firstName}}</td>
            <td>{{user.lastName}}</td>
            <td>{{user.email}}</td>
            <td v-if="user.superadmin">{{ $t("user.list.yes") }}</td><td v-if="!user.superadmin">{{ $t("user.list.no") }}</td>
            <td><span :class="[{'confirmed': user.confirmed}, {'not-confirmed': !user.confirmed}]"></span></td>
            <td class="actions me-2 text-end">
                
                <a class="disconnect" :class="{'on': user.disconnected}" @click="disconnectUser(user)"></a>
                <a class="ban" :class="{'on': user.banned}" @click="banUser(user)"></a>
                <a class="edit" @click="updateUser(user)"></a>
                <a class="delete" @click="deleteUser(user)"></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    
  </div>

  <user-new-component @created="createdUser" @canceled="createUserCanceled" :action="newAction" />
  <user-update-component @updated="updatedUser" @canceled="updateUserCanceled" :userToUpdate="userToUpdate" />
  <user-edit-privileges-component @updated="editedPrivilegesUser" @canceled="editPrivilegesUserCanceled" :userToEditPrivileges="userToEditPrivileges" />
  <user-delete-component @deleted="deletedUser" @canceled="deleteUserCanceled" :userToDelete="userToDelete" />

  <user-disconnect-component @disconnected="disconnectedUser" @canceled="disconnectUserCanceled" :userToDisconnect="userToDisconnect" />
  <user-ban-component @banned="bannedUser" @canceled="banUserCanceled" :userToBan="userToBan" />  

</template>

<script>

import $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable';

import privilegeConfig from '@/configs/privilegeConfig';
import userService from '@/services/userService';
import eventConfig from '@/configs/eventConfig';

import UserNewComponent from '../user/UserNewComponent.vue'
import UserUpdateComponent from '../user/UserUpdateComponent.vue'
import UserDeleteComponent from '../user/UserDeleteComponent.vue'

import UserDisconnectComponent from '../user/UserDisconnectComponent.vue'
import UserBanComponent from '../user/UserBanComponent.vue'


export default {
  name: 'UsersComponent',
  emits: [eventConfig.LOADED],
  props: {
    session: Object,
    superadmin: Boolean,
    loadAction: Boolean,
  },
  data: function () {
    return {
      users: null,
      eventConfig: eventConfig,
      newAction: false,
      userToUpdate: null,
      userToDelete: null,
      userToDisconnect: null,
      userToBan: null,
      userToEditPrivileges: null
    }
  },
  components: {
    'user-new-component': UserNewComponent,
    'user-update-component': UserUpdateComponent,
    'user-delete-component': UserDeleteComponent,
    'user-disconnect-component': UserDisconnectComponent,
    'user-ban-component': UserBanComponent
  },
  watch: 
  { 
    loadAction: function() {
      if(this.loadAction)
      {
        this.loadUsers();
      }
    
    }
  },
  mounted () {
    this.loadUsers();
  },
  methods: 
  {

    loadUsers() {  
      
      userService.getList().then(
        response => {

          this.users = response;
          this.$emit(eventConfig.LOADED);
        },
        errors => {
          
        }
      );
    },
    
    createUser()
    {
      this.newAction = true;
    },
    createdUser(user)
    {
      this.newAction = false;
      this.loadUsers();
    },
    createUserCanceled()
    {
      this.newAction = false;
    },
   
    updateUser(user)
    {
      this.userToUpdate = user;
    },
    updatedUser(user)
    {
      
      this.userToUpdate = null;
      this.loadUsers();
    },
    updateUserCanceled()
    {
      this.userToUpdate = null;
    },
    
    
    editPrivilegesUser(user)
    {
      this.userToEditPrivileges = user;
    },
    editedPrivilegesUser(user)
    {
      
      this.userToEditPrivileges = null;
    },
    editPrivilegesUserCanceled()
    {
      this.userToEditPrivileges = null;
    },

  
    deleteUser(user)
    {
      this.userToDelete = user;
    },
    deleteUserCanceled()
    {
      this.userToDelete = null;
    },
    deletedUser()
    {
      this.userToDelete = null;
      this.loadUsers();
    },

    disconnectUser(user)
    {
      this.userToDisconnect = user;
    },
    disconnectUserCanceled()
    {
      this.userToDisconnect = null;
    },
    disconnectedUser()
    {
      this.userToDisconnect = null;
      this.loadUsers();
    },

    banUser(user)
    {
      this.userToBan = user;
    },
    banUserCanceled()
    {
      this.userToBan = null;
    },
    bannedUser()
    {
      this.userToban = null;
      this.loadUsers();
    }
  }
 
}
</script>

<style scoped lang="scss">

.actions a {
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    width: 40px;
    height: 40px;
}

.actions a.disconnect {
  background-image: url(@/assets/images/ico-user-disconnect.svg);
}
.actions a.disconnect.on {
  background-image: url(@/assets/images/ico-user-disconnect-on.svg);
}
.actions a.ban {
  background-image: url(@/assets/images/ico-user-ban.svg);
}
.actions a.ban.on {
  background-image: url(@/assets/images/ico-user-ban-on.svg);
}
.actions a.groups {
  background-image: url(@/assets/images/ico-group.svg);
}

.actions a.edit {
  background-image: url(@/assets/images/ico-edit.svg);
}

span.confirmed {
      background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    width: 40px;
    height: 40px;
  background-image: url(@/assets/images/ico-confirmed.svg);
}
span.not-confirmed {
      background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    width: 40px;
    height: 40px;
  background-image: url(@/assets/images/ico-not-confirmed.svg);
}

.actions a.privileges {
  background-image: url(@/assets/images/ico-privileges.svg);
}


.actions a.delete {
  background-image: url(@/assets/images/ico-trash.svg);
}
</style>