import axios from 'axios';
import cookieService from './cookieService';
import router from '../router'
import apiService from './apiService';

export default class sessionService {


    static getList () {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/session/list")
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }

    static getPlayableSession (id) {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/session/getPlayable/"+id)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }

    static getSession (id) {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/session/get/"+id)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }


    static isCombinationAvailable (initialCombination) {
        
        return new Promise((resolve, reject) => {
            let combination = [];
            for(let button of initialCombination)
            {
                if(button.selected)
                {
                    combination.push(button.letter);
                }
            }
            const data = {
                combination: combination
            }
            
            apiService.post("/session/isCombinationAvailable", data)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }

    static create (session) {

        let combination = [];
        for(let button of session.combination)
        {
            if(button.selected)
            {
                combination.push(button.letter);
            }
        }
        let sess = JSON.parse(JSON.stringify(session));
        sess.combination = combination;
        
        return new Promise((resolve, reject) => {

            apiService.post("/session/new", sess )
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);   
            });
         });
       
    }  

    static cancelUploadVideo () {
        apiService.cancelUpload();
    }
  
    
    static uploadVideoGCS (url, datas) {

        
        return new Promise((resolve, reject) => {

            apiService.uploadGCS(url, datas )
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);   
            });
         });
       
    }      
    
    static markUploadComplete (datas) {

        return new Promise((resolve, reject) => {

            apiService.post("/session/markUploadComplete", datas )
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);   
            });
        });
    }      
    
    static update (session) {
        var url = "/session/edit";
        
        let combination = [];
        for(let button of session.combination)
        {
            if(button.selected)
            {
                combination.push(button.letter);
            }
        }
        let sess = JSON.parse(JSON.stringify(session));
        sess.combination = combination;


        return new Promise((resolve, reject) => {
            
            apiService.post(url, sess)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }      

    static delete (session) {
        
        return new Promise((resolve, reject) => {
            apiService.delete("/session/remove/"+session.id)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }  


}
