<template>

  <div id="newSessionModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("session.new.title") }}</h5>
          <button type="button" class="btn-close" @click="cancel()" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p v-if="!uploadInProgress">{{ $t("session.new.instructions") }}</p>
          <form @submit.prevent="">
            
            <div v-if="!uploadInProgress" class="form-group mb-3">
              <label for="nameInput">{{ $t("session.new.nameLabel") }}</label>
              <input id="nameInput" type="text" class="form-control" :class="{ 'is-invalid': nameError}" v-model="session.name">      
              <div v-if="nameError" class="invalid-feedback">
                {{nameError}}
              </div>   
            </div>

            <div v-if="!uploadInProgress" class="form-group mb-3">
              <label>{{ $t("session.new.combinationLabel") }}</label>
              <combination-component @update="updateCombination" :initialCombination="session.combination" />
              <div v-if="combinationExistsError || session.forceCombination" class="form-check form-switch mt-3">
                <input class="form-check-input" type="checkbox" id="forcecombination" v-model="session.forceCombination">
                <label class="form-check-label" for="forcecombination">{{ $t("session.new.forceCombination") }}</label>
              </div>
              <div v-if="combinationExistsError && !session.forceCombination" class="alert alert-danger mt-3" role="alert">
                {{ combinationExistsError }}
              </div>
              <div v-if="combinationError" class="alert alert-danger mt-3" role="alert">
                {{ combinationError }}
              </div>

              <div v-if="combinationSuccess" class="alert alert-success mt-3" role="alert">
                {{ combinationSuccess }}
              </div>
            </div>

            <!--
            <div v-if="!uploadInProgress" class="form-group mb-3">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="videoFormatNew" id="radioVideo360New" value="360" v-model="session.type">
                <label class="form-check-label" for="radioVideo360New">
                  <div>{{ $t("session.new.video360") }}</div>
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="videoFormatNew" id="radioVideo180New" value="180" v-model="session.type">
                <label class="form-check-label" for="radioVideo180New">
                  {{ $t("session.new.video180") }}
                </label>
              </div>
            </div>
          -->

            <div class="form-group mb-3">
              <label for="formIntroFile" class="form-label">{{ $t("session.new.introVideoLabel") }}</label>
              <div v-if="session.introVideo" class="actions d-flex">
                <div>{{ session.introVideoName }}</div>
                <a v-if="!uploadInProgress" class="delete" @click="introVideofileUnpicked">
                  <span>Delete</span>
                </a>
                <loading-component v-if="uploadIntroInProgress"></loading-component>
              </div>
              <input v-if="!session.introVideo" class="form-control" type="file" id="formIntroFile" :class="{ 'is-invalid': introVideoError}" accept="video/mp4" @change="introVideofilePicked">
              <div v-if="introVideoError" class="invalid-feedback">
                {{introVideoError}}
              </div> 
            </div>
            
            <div class="form-group mb-3">
              <label for="formLoopFile" class="form-label">{{ $t("session.new.loopVideoLabel") }}</label>
              <div v-if="session.loopVideo" class="actions d-flex">
                <div>{{ session.loopVideoName }}</div>
                <a v-if="!uploadInProgress" class="delete" @click="loopVideofileUnpicked">
                  <span>Delete</span>
                </a>
                <loading-component v-if="uploadLoopInProgress"></loading-component>
              </div>
              <input v-if="!session.loopVideo" class="form-control" type="file" id="formLoopFile" :class="{ 'is-invalid': loopVideoError}" accept="video/mp4" @change="loopVideofilePicked">
              <div v-if="loopVideoError" class="invalid-feedback">
                {{loopVideoError}}
              </div> 
            </div>

            <div class="form-group mb-3">
              <label for="formOutroFile" class="form-label">{{ $t("session.new.outroVideoLabel") }}</label>
              <div v-if="session.outroVideo" class="actions d-flex">
                <div>{{ session.outroVideoName }}</div>
                <a v-if="!uploadInProgress" class="delete" @click="outroVideofileUnpicked">
                  <span>Delete</span>
                </a>
                <loading-component v-if="uploadOutroInProgress"></loading-component>
              </div>
              <input v-if="!session.outroVideo" class="form-control" type="file" id="formOutroFile" :class="{ 'is-invalid': outroVideoError}" accept="video/mp4" @change="outroVideofilePicked">
              <div v-if="outroVideoError" class="invalid-feedback">
                {{outroVideoError}}
              </div> 
            </div>
            
            <div v-if="error" class="alert alert-danger" role="alert">
              {{error}}
            </div>
            
          </form>
        </div>
        <div class="modal-footer">
          <button v-if="uploadInProgress" type="button" class="btn btn-danger" @click="cancelUpload()">{{ $t("session.new.cancelLabel") }}</button>
          <button v-if="!uploadInProgress" type="button" class="btn btn-secondary" @click="cancel()">{{ $t("session.new.closeLabel") }}</button>
          <button v-if="!uploadInProgress" type="button" class="btn btn-primary" @click="createSession()">{{ $t("session.new.createLabel") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from 'bootstrap';
import $ from 'jquery'

import sessionService from '../../services/sessionService';
import eventConfig from '@/configs/eventConfig';

import LoadingComponent from '@/components/util/LoadingComponent.vue';
import CombinationComponent from '@/components/session/CombinationComponent.vue';
const emptyCombination = function() {
  return [
          {
            letter: "a",
            selected: false
          }, 
          {
            letter: "b",
            selected: false
          }, 
          {
            letter: "x",
            selected: false
          }, 
          {
            letter: "y",
            selected: false
          }, 
          {
            letter: "l1",
            selected: false
          }, 
          {
            letter: "l2",
            selected: false
          }, 
          {
            letter: "r1",
            selected: false
          }, 
          {
            letter: "r2",
            selected: false
          }
        ];
}

const emptySession = function() {
    return {
      name: null,
      type: "360",
      forceCombination: false,
      combination: emptyCombination()
     };
};

export default {
  name: 'SessionNewComponent',
  props: {
    action: Boolean
  },
  watch: 
  { 
    action: function() {
      if(this.action)
      {
        this.openModal();
      }
    }
  },
  data: function () {
    return {
      session: emptySession(),
      newModal: null,
      error: null,
      nameError: null,
      combinationSuccess: null,
      combinationError: null,
      combinationExistsError: null,
      introVideoError: null,
      loopVideoError: null,
      outroVideoError: null,
      uploadIntroInProgress: null,
      uploadLoopInProgress: null,
      uploadOutroInProgress: null,
      uploadInProgress: null,
      introWriteUrl: null,
      loopWriteUrl: null,
      outroWriteUrl: null
    }
  },
  components: {
    'combination-component': CombinationComponent,
    'loading-component': LoadingComponent
  },
  mounted () {
    
    this.newModal = new Modal($('#newSessionModal'));
    
    this.eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
      this.cancel();
    });

  },
  methods: {
    updateCombination(combination)
    {
      this.session.combination = combination;

      this.combinationError = null;
      this.combinationExistsError = null;
      this.combinationSuccess = null;

      sessionService.isCombinationAvailable(combination).then(
        response => {
          this.combinationSuccess = this.$t(response.msg.msg_code);
        },
        errors => {
          if(errors.response)
          {
            this.parseErrors(errors.response.data);
          }
        }
      );
    },
    cancelUpload()
    {
      sessionService.cancelUploadVideo();
      this.newModal.hide();
      this.$emit(eventConfig.CREATED);
    },
    cancel()
    {
      this.newModal.hide();
      this.$emit(eventConfig.CANCELED);
    },
    resetCurrentSession()
    {
      this.error = null;
      this.nameError = null;
      this.combinationSuccess = null;
      this.combinationError = null;
      this.combinationExistsError = null;
      this.introVideoError = null;
      this.loopVideoError = null;
      this.outroVideoError = null;
      this.uploadIntroInProgress = null;
      this.uploadLoopInProgress = null;
      this.uploadOutroInProgress = null;
      this.uploadInProgress = null;
      this.introWriteUrl = null;
      this.loopWriteUrl = null;
      this.outroWriteUrl = null;

      this.session = emptySession();

    },
    openModal() {
      this.resetCurrentSession();
      this.newModal.show();
    },
    introVideofilePicked (event) {
      const files = event.target.files;
      this.session.introVideoName = files[0].name;
      this.session.introVideo = files[0];
    },
    introVideofileUnpicked () {
      this.session.introVideoName = null;
      this.session.introVideo = null;
    },
    loopVideofilePicked (event) {
      const files = event.target.files;
      this.session.loopVideoName = files[0].name;
      this.session.loopVideo = files[0];
    },
    loopVideofileUnpicked () {
      this.session.loopVideoName = null;
      this.session.loopVideo = null;
    },
    outroVideofilePicked (event) {
      const files = event.target.files;
      this.session.outroVideoName = files[0].name;
      this.session.outroVideo = files[0];
    },
    outroVideofileUnpicked () {
      this.session.outroVideoName = null;
      this.session.outroVideo = null;
    },        
    createSession() {
      
      this.nameError = null;
      this.introVideoError = null;
      this.loopVideoError = null;
      this.outroVideoError = null;
      this.combinationError = null;
      this.combinationExistsError = null;
      this.combinationSuccess = null;
      this.error = null;
      this.introWriteUrl = null;
      this.loopWriteUrl = null;
      this.outroWriteUrl = null;


      let s = {
        name: this.session.name,
        combination: this.session.combination,
        type: this.session.type,
        introVideoName: this.session.introVideoName,
        loopVideoName: this.session.loopVideoName,
        outroVideoName: this.session.outroVideoName
      }

      sessionService.create(s).then(
        response => {
          
          this.introWriteUrl = response.introWriteUrl;
          this.loopWriteUrl = response.loopWriteUrl;
          this.outroWriteUrl = response.outroWriteUrl;
          this.session.id = response.session.id;
          
          if(this.session.introVideo)
          {
            this.uploadVideoGCS('intro');
          }
          else if(this.session.loopVideo)
          {
            this.uploadVideoGCS('loop');
          }else if(this.session.outroVideo)
          {
            this.uploadVideoGCS('outro');
          }else
          {
            this.newModal.hide();
            this.$emit(eventConfig.CREATED);
          }

          
        },
        errors => {
          console.log(errors)
          if(errors.response)
          {
            this.parseErrors(errors.response.data);
          }
            
            this.updateCombination(this.session.combination);
        }
      );
      
    },
    markUploadComplete(fileLabel) {
      var data = {
        id: this.session.id,
        fileLabel: fileLabel
      }
      sessionService.markUploadComplete(data).then(response => {
          
          switch(fileLabel)
          {
            case 'intro':
              if(this.session.loopVideo)
              {
                this.uploadVideoGCS('loop');
              }else if(this.session.outroVideo)
              {
                this.uploadVideoGCS('outro');
              }else
              {
                this.newModal.hide();
                this.$emit(eventConfig.CREATED);
              }
              
            break;
            case 'loop':
              if(this.session.outroVideo)
              {
                this.uploadVideoGCS('outro');
              }else
              {
                this.newModal.hide();
                this.$emit(eventConfig.CREATED);
              }
            break;
            case 'outro':
              this.newModal.hide();
              this.$emit(eventConfig.CREATED);
            break;
          }

          
        },
        errors => {
          if(errors.response)
          {
            this.parseErrors(errors.response.data);
          }
        }
      );

    },
    uploadVideoGCS(fileLabel) {

      var url;
      var file;

      switch(fileLabel)
      {
        case 'intro':
          file = this.session.introVideo;
          url = this.introWriteUrl;
          this.uploadIntroInProgress = true;
        break;
        case 'loop':
          file = this.session.loopVideo;
          url = this.loopWriteUrl;
          this.uploadLoopInProgress = true;
        break;
        case 'outro':
          file = this.session.outroVideo;
          url = this.outroWriteUrl;
          this.uploadOutroInProgress = true;
        break;
      }

      this.uploadInProgress = true;

      var datas = {
        'file': file
      };

      sessionService.uploadVideoGCS(url, datas).then(
        response => {

          this.uploadInProgress = null;
          this.uploadIntroInProgress = null;
          this.uploadLoopInProgress = null;
          this.uploadOutroInProgress = null;

          this.markUploadComplete(fileLabel);
          
        },
        errors => {
          
          if(errors.response)
          {
            this.parseErrors(errors.response.data);
          }

            this.uploadInProgress = null;
            this.uploadIntroInProgress = null;
            this.uploadLoopInProgress = null;
            this.uploadOutroInProgress = null;
        }
      );
    },
    parseErrors(data)
    {
      
      if(data.errors)
      {
        for(let error of data.errors)
        {
          switch(error.param)
          {
            case 'slug':
              this.slugError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'name':
              this.nameError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'combination':
              this.combinationError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'combinationExists':
              this.combinationExistsError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break; 
            case 'introVideoName':
              this.introVideoError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'loopVideoName':
              this.loopVideoError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'outroVideoName':
              this.outroVideoError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break; 
          }
        }
      }else
      {
        this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
      }

      
    }
  }
}
</script>

<style scoped lang="scss">

.actions div {
  
  line-height: 40px;
}

.actions a {
  background-repeat: no-repeat;
  background-position: center center;
  width: 40px;
  height: 40px;
}

.actions a span {
  display: none;
}
.actions a.delete {
  background-image: url(../../assets/images/ico-trash.svg);
}
</style>