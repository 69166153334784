<template>
  <div class="d-flex">
    <div class="spinner-border ms-2" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="ms-2">
      <strong>{{ percent }}</strong>
    </div>
    
  </div>

</template>


<script>
import eventConfig from '@/configs/eventConfig';
import eventDispatcher from '@/eventDispatcher';



export default {
  name: 'LoadingComponent',
  data: function () {
    return {
      percent: null
    }
  },
  mounted () {
    
    this.eventDispatcher.on(eventConfig.PROGRESS, (percent) => {
      this.percent = (Math.floor(percent*100)/100)+'%';
    });

  },
  methods: {
   
  }
}
</script>

