import axios from 'axios';
import cookieService from './cookieService';
import router from '../router'
import apiService from './apiService';

export default class sessionService {


    static get () {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/parameters", false)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }



}
