<template>
  <div>
    <div class="buttons d-flex pt-2">
      <a class="button" v-for="(button, index) in combination" v-bind:key="index" @click="toggle(button)" :class="{'selected': button.selected}">
        {{ button.letter }}
      </a>
    </div>
  </div>
</template>

<script>
import eventConfig from '@/configs/eventConfig';


export default {
  name: 'CombinationComponent',
  props: {
    initialCombination: Array,
    disabled: Boolean
  },
  data: function () {
    return {
      combination: this.initialCombination
    }
  },
  watch: 
  { 
    initialCombination: function() {
      if(this.initialCombination != this.combination)
      {
        this.combination = this.initialCombination;
      }
    }
  },
  components: {
    
  },
  mounted () {
    

  },
  methods: {
    toggle(button) {

      if(!this.$props.disabled)
      {

        for(let i=0; i<this.combination.length; i++)
        {
          if(button.letter == this.combination[i].letter)
          {
            this.combination[i].selected = !this.combination[i].selected;
          }
          
        }

        this.$emit(eventConfig.UPDATE, this.combination);

      }
    }
  }
}
</script>

<style scoped lang="scss">
.buttons {

}
.button {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border: 1px solid black;
  border-radius: 40px;
  margin-right: 10px;
  text-transform: capitalize;
  text-decoration: none;
}
.button.selected {
  color: white;
  background-color: black;
}


@media print {
    .no-print {
      display: none;
    }

    .button.selected {
      color: black;
    }
  }
</style>