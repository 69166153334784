<template>
  <div class="container-fluid h-100 after-navbar">
    <div class="row h-100">
      <div class="col col-12 p-0 h-100 d-flex flex-column">
              
        <div class="flex-grow-1 d-flex flex-column">
            <nav class="navbar navbar-light bg-light border-bottom">
              <div class="container-fluid p-0 ps-2">
                <ul class="nav nav-tabs">
                  <li class="nav-item">
                    <a class="nav-link" :class="{'active': currentState=='users'}" @click="selectUsers()">{{ $t("usersGroups.adminUsers") }}</a>
                  </li>
                </ul>

              </div>
            </nav>
            
            <users-component v-if="currentState=='users'" @loaded="loadedUsers" :superadmin="superadmin" :loadAction="loadUsersAction" />            
              
        </div>
      

      </div>
    </div>
  </div>
  

</template>

<script>
import UsersComponent from "@/components/user/UsersComponent.vue";



export default {
  name: 'UsersGroupsComponent',
  props: {
    superadmin: Boolean
  },
  components: {
      'users-component': UsersComponent
  },
  data: function () {
    return {
      currentState: 'users',
      loadUsersAction: true,
      loadPrivilegesGroupsAction: false
    }
  },
  mounted () {
    

  },
  methods: {

    selectUsers()
    {
      this.loadUsersAction = true;
      this.currentState = 'users';
    },
    loadedUsers()
    {
      this.loadUsersAction = false;
    }
  }
}
</script>
